import React from 'react'
import PropTypes from 'prop-types'
import slugify from '@sindresorhus/slugify'
import { formatToNumberWithCommas } from 'helpers'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H2, H4, Text } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import Link from 'components/Link'
import ChoroplethChart from 'components/ChoroplethChart'

const States = ({ pageContext: { states, name: countryName, fullName, agenciesNumber, map } }) => {
  let data = []
  if (map) {
    data = states.map(({ name, value }) => {
      return { id: name, value }
    })
  }

  const onChoose = ({ id }) => {
    const state = states.find(({ name }) => name === id)
    window.location.href = `/top-marketing-agencies/${slugify(countryName)}/${slugify(state.name)}`
  }

  return (
    <App>
      <SEO
        title={`Social media marketing agencies in ${fullName}`}
        description={`Connect with thousands of top marketing agencies ${fullName}`}
        path={`/top-marketing-agencies/${countryName}/`}
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H2>{`Top marketing agencies in ${fullName}`}</H2>
          <H4 mt="m" color="secondaryText" opacity={0.8}>
            Connect with thousands top marketing agencies that we have researched, compiled and verified in {fullName}
          </H4>
          {data.length > 0 && (
            <Box height={{ mobile: '200px', tablet: '420px' }} width="100%">
              <ChoroplethChart data={data} map={map} onClick={onChoose} />
            </Box>
          )}
          <Grid
            mt="m"
            py="m"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(4, 1fr)']}
            gridGap="m"
          >
            {states.map(({ id, name, value }) => (
              <Text
                key={id}
                as={Link}
                href={`/top-marketing-agencies/${slugify(countryName)}/${slugify(name)}`}
                textAlign="left"
                fontSize="m"
                fontWeight="medium"
                antiAliased
                color="primary"
              >
                {`${name} (${formatToNumberWithCommas(value)})`}
              </Text>
            ))}
            {agenciesNumber && (
              <Text
                as={Link}
                href={`/marketing-agencies/country/${slugify(countryName)}`}
                textAlign="left"
                fontSize="m"
                fontWeight="medium"
                antiAliased
                color="primary"
              >
                {`All agencies in ${fullName} (${formatToNumberWithCommas(agenciesNumber)})`}
              </Text>
            )}
          </Grid>

          <Grid
            mt="xxl"
            py="s"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(3, 1fr)']}
            gridGap="m"
          >
            {states.map(({ id, name, value }) => (
              <Text
                key={id}
                as={Link}
                href={`/top-marketing-agencies/${slugify(countryName)}/${slugify(name)}`}
                textAlign="left"
                fontSize="s"
                fontWeight="xs"
                antiAliased
                color="secondaryText"
              >
                {`Top agencies in ${name}, ${fullName}`}
              </Text>
            ))}
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

States.propTypes = {
  pageContext: {
    agenciesNumber: 0,
  },
}

States.propTypes = {
  pageContext: PropTypes.shape({
    states: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    agenciesNumber: PropTypes.number,
    map: PropTypes.object,
  }).isRequired,
}

export default States
